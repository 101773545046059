import React from "react"
// Utilities
import kebabCase from "lodash/kebabCase"
// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"


class TagsPage extends React.Component {
  render() {
    const {
      data: {
        allMarkdownRemark: { group },
        site: {
          siteMetadata: { title },
        },
      },
    } = this.props;
    const siteTitle = this.props.data.site.siteMetadata.title


    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={title}
          description="toutes les catégories"
        />
        <div>
          <h1>Catégories</h1>
          <ul className="is-unstyled cards">
            {group.map(tag => (
              <li className="cards_item" key={tag.fieldValue}>
                <Link className="cards_link" to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue}
                </Link>
                <div className="cards_count">{tag.totalCount}</div>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
    )
  }
}
export default TagsPage


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`